import Rails from "@rails/ujs"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

import AdminChoices from "./admin/choices"
import AdminGraphs from "./admin/graphs"

import "./stylesheets/admin.scss"

const imageContext = require.context("./images", true, /.(png|jpg|svg)$/)
imageContext.keys().forEach((key) => { imageContext(key) })

const Stimulus = window.Stimulus = Application.start()
const stimulusContext = require.context("./admin/controllers/", true, /\.ts$/)
Stimulus.load(definitionsFromContext(stimulusContext))

Rails.start()
AdminChoices.start()
AdminGraphs.start()
